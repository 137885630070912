import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ href, children }) => (
  <a href={href} target="_blank" rel="noreferrer noopener">{children}</a>
);

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default ExternalLink;
