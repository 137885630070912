import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { window } from 'browser-monads';
import { vh } from '../helpers/viewportHelpers';

class ViewportOnScrollChecker extends React.Component {
  constructor (props) {
    super(props);

    this.currentViewHeight = vh(100);
    this.currentScrollY = window ? window.scrollY : 0;
    this.currentIsPastScrollPoint = false;
  }

  componentDidMount () {
    if (window) {
      this.currentViewHeight = vh(100);
      this.currentScrollY = window ? window.scrollY : 0;
      this.recalculateScrollPoint();
      window.addEventListener('scroll', this.handleScrollChange);
      window.addEventListener('resize', this.handleResizeChange);
    }
  }

  componentWillUnmount () {
    if (window) {
      window.removeEventListener('scroll', this.handleScrollChange);
      window.removeEventListener('resize', this.handleResizeChange);
    }
  }

  handleScrollChange = _.debounce(() => {
    this.currentScrollY = window.scrollY;
    this.recalculateScrollPoint();
  }, 0);

  handleResizeChange = _.debounce(() => {
    this.currentViewHeight = vh(100);
    this.recalculateScrollPoint();
  }, 0);

  recalculateScrollPoint = () => {
    const isPastScrollPoint = this.isPastScrollPoint();

    if (this.currentIsPastScrollPoint !== isPastScrollPoint) {
      this.currentIsPastScrollPoint = isPastScrollPoint;
      this.props.onScrollChanged(isPastScrollPoint);
    }
  };

  isPastScrollPoint = () => {
    const { currentScrollY, currentViewHeight } = this;
    const { fudgePercent } = this.props;

    return currentScrollY >= (currentViewHeight * fudgePercent);
  };

  render () {
    return null;
  }
}

ViewportOnScrollChecker.propTypes = {
  onScrollChanged: PropTypes.func.isRequired,
  fudgePercent: PropTypes.number,
};
export default ViewportOnScrollChecker;
