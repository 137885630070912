import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

const WeddingParty = () => (
  <Row>
    <Col lg={6} className="d-flex flex-column">
      <Card className="flex-1">
        <CardBody>
          <h4>Maid of Honor</h4>
          <p>Emily Schoen</p>

          <h4>Bridesmaids</h4>
          <p className="mb-0">Aaron Johnson</p>
          <p className="mb-0">Jess Sieck</p>
          <p className="mb-0">Evie Hintz</p>
          <p className="mb-0">Megan Neuenschwander</p>
        </CardBody>
      </Card>
    </Col>

    <Col lg={6} className="mt-2 mt-lg-0 d-flex flex-column">
      <Card className="flex-1">
        <CardBody>
          <h4>Best Man</h4>
          <p>Justin Scroggins</p>

          <h4>Groomsmen</h4>
          <p className="mb-0">Taysia Herrmann</p>
          <p className="mb-0">Conner Staples</p>
          <p className="mb-0">Justin Cunningham</p>
          <p className="mb-0">Chris Scherer</p>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

WeddingParty.propTypes = {
};

export default WeddingParty;
