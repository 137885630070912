import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

class LiveStreamModal extends React.Component {
  render () {
    const { show } = this.props;

    return (
      <Modal isOpen={show} toggle={this.props.hide} size="lg">
        <ModalHeader>Watch the Stream</ModalHeader>
        <ModalBody>
          <p>If you'd like to watch the wedding ceremony back check out the videos below. The first link is a traditional video of the wedding, the second is an interactive 360 degree panoramic video that can be moved as you watch.</p>

          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/5ukG-utTMTM"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />

          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/gENegeUIosY"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </ModalBody>
      </Modal>
    );
  }
}

LiveStreamModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

export default LiveStreamModal;
