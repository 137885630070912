import React from 'react';
import numeral from 'numeral';
import { CardElement } from '@stripe/react-stripe-js';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

class PaymentStep extends React.Component {
  render () {
    const { stripeKey, giftDetails, isSubmittingPayment } = this.props;

    if (!stripeKey || !giftDetails) {
      return null;
    }

    return (
      <div>
        <p>
          We can't thank you enough for your contribution and your support.
        </p>
        <CardElement className="payment-container" />

        <div className="text-center">
          <small className="text-muted">All payments are processed securely by Stripe, we will never see your credit
            card information.
          </small>
        </div>

        <hr />

        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Button block onClick={this.props.previousStep}>
              <i className="mdi mdi-chevron-double-left" /> Back
            </Button>
          </div>
          <div>
            {!isSubmittingPayment && <Button block color="primary" onClick={this.props.submitGiftPayment}>Submit {numeral(giftDetails.floatAmount).format('$0,0.00')} Gift</Button>}
            {isSubmittingPayment && <Button block color="primary"><i className="mdi mdi-spin mdi-refresh" /> Submitting</Button>}
          </div>
        </div>
      </div>
    );
  }
}

PaymentStep.propTypes = {
  stripeKey: PropTypes.string,
  giftDetails: PropTypes.object,
  isSubmittingPayment: PropTypes.bool.isRequired,
  previousStep: PropTypes.func,
  submitGiftPayment: PropTypes.func,
};

export default PaymentStep;
