import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import GiftDetailsStep from './GiftDetailsStep';
import superagent from 'superagent';
import appConfig from '../../appConfig';
import PaymentStep from './PaymentStep';

const step = {
  details: 'details',
  submit: 'submit',
};

const PaymentModal = (props) => {
  const { giftType } = props;
  const [currentStep, setCurrentStep] = useState(step.details);
  const [giftDetails, setGiftDetails] = useState({});
  const [stripeKey, setStripeKey] = useState('');
  const [isLoadingStripeKey, setIsLoadingStripeKey] = useState(false);
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setCurrentStep(step.details);
    setGiftDetails({});
    setStripeKey(undefined);
  }, [props.show]);

  async function submitGiftDetails (details) {
    setGiftDetails(details);
    await fetchStripeKey(details);
    setCurrentStep(step.submit);
  }

  async function fetchStripeKey (giftDetails) {
    try {
      setIsLoadingStripeKey(true);

      const { body } = await superagent.post(`${appConfig.baseUrl}/api/v1/gifts/stripe`)
        .send({ ...giftDetails, giftType });

      setIsLoadingStripeKey(false);

      setStripeKey(body);
    } catch (e) {
      toastr.error('An unknown error occurred. Please try again!');
      setIsLoadingStripeKey(false);
    }
  }

  const submitGiftPayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      setIsSubmittingPayment(true);
      const result = await stripe.confirmCardPayment(stripeKey, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: giftDetails.name,
          },
        },
      });

      setIsSubmittingPayment(false);

      if (result.error) {
        toastr.error(result.error.message || 'An unknown Stripe error occurred.', 'Stripe Error');
      } else if (result && result.paymentIntent) {
        if (result.paymentIntent.status === 'succeeded') {
          props.hide();
          toastr.success('Your gift has been received, we cannot thank you enough for your generosity.', 'Thank you!');
        }
      }
    } catch (e) {
      console.error({ e });
      setIsSubmittingPayment(false);
      toastr.error('An error occurred while submitting your payment... Please try again!');
    }
  };

  if (!giftType) {
    return null;
  }

  return (
    <Modal isOpen={props.show} toggle={props.hide}>
      <ModalHeader>You're Too Kind!</ModalHeader>
      <ModalBody>
        <Row>
          <Col mg={12}>
            {currentStep === step.details && (
              <GiftDetailsStep
                giftDetails={giftDetails}
                submitGiftDetails={submitGiftDetails}
                isLoadingStripeKey={isLoadingStripeKey}
              />
            )}
            {currentStep === step.submit && (
              <PaymentStep
                stripeKey={stripeKey}
                giftDetails={giftDetails}
                isSubmittingPayment={isSubmittingPayment}
                previousStep={() => setCurrentStep(step.details)}
                submitGiftPayment={submitGiftPayment}
              />
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

PaymentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  giftType: PropTypes.string,
  hide: PropTypes.func.isRequired,
};

export default PaymentModal;
