import { window, document } from 'browser-monads';

export const vh = v => {
  if (!window || !document) {
    return 0;
  }

  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  return (v * h) / 100;
};
