import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

const AboutUs = () => (
  <Row>
    <Col md={12} lg={5} className="d-flex align-items-center">
      <Card>
        <CardBody>
          <img
            className="img-fluid"
            src="/img/us.jpg"
          />
        </CardBody>
      </Card>
    </Col>
    <Col md={12} lg={7} className="d-flex align-items-center justify-content-center mt-2 m-md-0">
      <Card>
        <CardBody>
          <p className="text-center text-primary">
            Since our first date on May 22nd, 2015
            (kayaking on Whitewater Lake, Scott forgot the paddles) we have celebrated
            five anniversaries together. Those 5+ years encompass thousands of amazing moments
            including a high school graduation, two college graduations, 74 different
            concerts, trips to 14 different states, a handful of new jobs, and seemingly
            thousands of hours of laughter with friends and family. We have been blessed
            with the opportunity to learn new things about each other every day and cannot
            wait to say “I do” on a beautiful day in September.
          </p>

          <p className="text-center text-primary">
            We are ecstatic to be making this commitment in front of all the people we love.
            We have known from early on that what we have together is the real deal, and to
            make it official with the support of friends and family is profoundly meaningful
            to us.
          </p>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

AboutUs.propTypes = {
};

export default AboutUs;
