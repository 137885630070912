import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentModal from './Payment/PaymentModal';
import GiftType from './GiftType';
import appConfig from '../appConfig';

const stripePromise = loadStripe(appConfig.publishableApiKey);

export const GiftTypes = {
  weddingFund: 'WeddingFund',
  charity: 'Charity',
};

const Gifts = () => {
  const [giftType, setGiftType] = useState(undefined);
  return (
    <Elements stripe={stripePromise}>
      <Row>
        <Col md={12}>
          <GiftType Header="Contribute to Wedding Fund" giftType={GiftTypes.weddingFund} setGiftType={() => setGiftType(GiftTypes.weddingFund)}>
            <p>
              We do not expect any gifts from anyone. We are honored to have the opportunity to get married in front of the people we love the most in this world.
              If you would still like to give, we have elected to forgo the traditional registry and instead create a wedding fund. Any money contributed  will be
              used to benefit our future together (First House, Honeymoon, Paying Down Debts, etc). You can click the button below and send money digitally right through this site if you are comfortable doing so.
              If you would prefer to do so physically there will be a gift box at the wedding if that's more your style.
            </p>
          </GiftType>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col className="d-flex" md={12}>
          <GiftType Header="Charity" giftType={GiftTypes.charity} setGiftType={() => setGiftType(GiftTypes.charity)}>
            <p>
              We have been blessed with so much opportunity in this life and would be honored if you would spend your money helping
              those in need. Any money contributed to this fund will be distributed evenly between the following charities with missions we hold near
              and dear to our hearts.
            </p>

            <Row className="mt-3">
              <Col className="d-flex mb-3 mb-md-0" md={6}>
                <Card>
                  <CardHeader>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Arthritis National Research Foundation</span>
                      <a href="https://curearthritis.org/" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-link-variant" /></a>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <strong>Mission: To provide initial research funding to brilliant, investigative scientists with new ideas to cure arthritis and related autoimmune diseases.</strong>
                  </CardBody>
                </Card>
              </Col>
              <Col className="d-flex" md={6}>
                <Card>
                  <CardHeader>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Kathy's House</span>
                      <a href="https://kathys-house.org/" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-link-variant" /></a>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <strong>Mission: Kathy’s House is a hospital guest house committed to providing affordable lodging and caring support in a “home away from home” environment for families who need to travel to Milwaukee for medical care.</strong>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Col className="d-flex mb-3 mb-md-0" md={6}>
                <Card>
                  <CardHeader>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>CASA of Rock County</span>
                      <a href="https://www.casarockcounty.org/" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-link-variant" /></a>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <strong>Mission: To Recruit, train and support community volunteers to advocate for the best interest of children who have experienced abuse or neglect.</strong>
                  </CardBody>
                </Card>
              </Col>
              <Col className="d-flex" md={6}>
                <Card>
                  <CardHeader>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Doctors Without Borders</span>
                      <a href="https://www.doctorswithoutborders.org/" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-link-variant" /></a>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <strong>Mission: To provide lifesaving medical care to those most in need.</strong>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </GiftType>
        </Col>
      </Row>

      <PaymentModal show={!!giftType} giftType={giftType} hide={() => setGiftType(undefined)} />
    </Elements>
  );
};

Gifts.propTypes = {
};

export default Gifts;
