import React from 'react';
import _ from 'lodash';
import { Card, CardBody, Col, Row } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import ExternalLink from './ExternalLink';
import mapStyle from './mapStyle';
import MapMarker from '../Components/MapMarker';

const DayOfDetails = () => (
  <>
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <Row>
              <Col md={7} className="d-flex flex-column justify-content-center text-primary text-center">
                <div>
                  <h3>Sept 5th, 2020</h3>

                  <p className="mb-1">
                    Both the ceremony and the reception will be held at <ExternalLink href="https://www.creamery201.com/">Creamery 201</ExternalLink> in Fort Atkinson, Wisconsin.
                    The ceremony will begin at 3pm. After two become one, dinner will be served with drinks and dancing to follow! There is ample parking provided by the venue. We
                    will also have a shuttle going between the venue and hotels periodically during the evening.
                  </p>
                  <p className="mb-1">
                    We invite you to be fully present with us during the ceremony, so we ask that cell phones and
                    cameras are turned off and put away until we are officially Mr. and Mrs.! We will happily
                    share the amazing photos captured by our professional photographers.
                  </p>
                  <p className="mb-2">
                    We would like for this to be an adults-only occasion, and we encourage you to enjoy the
                    evening as a worry-free night off without your little ones!
                  </p>

                  <h5>Creamery 201</h5>
                  <p className="mb-0">201 N Main St</p>
                  <p>Fort Atkinson, WI 53538</p>
                </div>
              </Col>
              <Col md={5}>
                <div style={{ height: 500, width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBsvaQWGmkypDp5KX0GKzaJI371ilip5Qg' }}
                    defaultCenter={{ lat: 42.930222, lng: -88.837150 }}
                    defaultZoom={15}
                    distanceToMouse={_.noop}
                    options={{ styles: mapStyle }}
                  >
                    <MapMarker lat={42.930222} lng={-88.837150} />
                  </GoogleMapReact>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Row className="mt-3">
      <Col md={12}>
        <img className="img-fluid" src="/img/creamery.jpg" />
      </Col>
    </Row>
  </>
);

DayOfDetails.propTypes = {
};

export default DayOfDetails;
