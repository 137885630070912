import React, { useState } from 'react';
import animateScrollTo from 'animated-scroll-to';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import { Collapse, Nav, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

const NavBar = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Nav
      id="nav"
      className={classnames({
        'navbar fixed-top navbar-expand-lg navbar-dark': true, 'bg-primary': !props.transparentEnabled || isOpen || props.navbarTransparent,
      })} style={{ transition: '.5s ease' }}
    >
      <NavbarToggler onClick={toggle} />
      <NavbarBrand className="d-none d-lg-block fade-in" href="#header" onClick={() => scrollToId('header')}>Scott & Micaela</NavbarBrand>

      <Collapse isOpen={isOpen} navbar>
        <Nav className="fade-in mr-auto" navbar>
          <NavItem className="d-block d-md-none">
            <NavLink href="#header" onClick={() => scrollToId('header')}>Home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#day-of" onClick={() => scrollToId('day-of')}>The Day Of</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#wedding-party" onClick={() => scrollToId('wedding-party')}>Wedding Party</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#accommodations" onClick={() => scrollToId('accommodations')}>Accommodations</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#gifts" onClick={() => scrollToId('gifts')}>Gifts</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="pointer" onClick={props.openCovidModal}>COVID</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="pointer" onClick={props.openLiveStreamModal}>Watch the Ceremony</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Nav>
  );

  function scrollToId (id) {
    if (isOpen) {
      setIsOpen(false);
    }

    animateScrollTo(document.getElementById(id), { verticalOffset: -60 });
  }
};

NavBar.propTypes = {
  transparentEnabled: PropTypes.bool,
  navbarTransparent: PropTypes.any,
  openCovidModal: PropTypes.func.isRequired,
  openLiveStreamModal: PropTypes.func.isRequired,
};

export default NavBar;
