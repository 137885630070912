import React from 'react';
import _ from 'lodash';
import toastr from 'toastr';
import { Button, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';

class GiftDetailsStep extends React.Component {
  constructor (props) {
    super(props);

    const { name, message, amount, floatAmount } = props.giftDetails || {};
    this.state = {
      name: name || '',
      message: message || '',
      amount: amount || '',
      floatAmount: floatAmount || 0,
    };
  }

  setName = (e) => {
    this.setState({ name: e.target.value });
  }

  setMessage = (e) => {
    this.setState({ message: e.target.value });
  }

  setAmount = (e, maskedValue, floatValue) => {
    this.setState({ amount: maskedValue, floatAmount: floatValue });
  }

  submit = () => {
    const { name, message, amount, floatAmount } = this.state;

    if (!_.trim(name)) {
      toastr.error('Please enter your name.');
      return;
    }

    if (!_.trim(message)) {
      toastr.error('Please enter a message.');
      return;
    }

    if (floatAmount <= 0) {
      toastr.error('You must enter your gift amount.');
      return;
    }

    if (floatAmount < 1) {
      toastr.error('Stripe does not allow payments less than $1.');
      return;
    }

    this.props.submitGiftDetails({
      name: name,
      message: message,
      amount: amount,
      floatAmount: floatAmount,
    });
  }

  render () {
    const { name, message, amount } = this.state;
    const { isLoadingStripeKey } = this.props;

    return (
      <div>
        <p>
          Please leave your name and a message for us in the form below. Again, we cannot thank you enough for your support.
        </p>

        <FormGroup>
          <Label>Name</Label>
          <input className="form-control" value={name} onChange={this.setName} />
        </FormGroup>

        <FormGroup>
          <Label>Message</Label>
          <textarea className="form-control" value={message} onChange={this.setMessage} />
        </FormGroup>

        <hr />

        <FormGroup>
          <Label>Gift Amount</Label>

          <CurrencyInput
            className="form-control form-control-lg" value={amount} onChangeEvent={this.setAmount}
          />
        </FormGroup>

        <div className="d-flex align-items-center justify-content-between">
          <div />
          <div>
            {!isLoadingStripeKey && <Button block color="primary" onClick={this.submit}>Next</Button>}
            {isLoadingStripeKey && <Button block color="primary"><i className="mdi mdi-spin mdi-refresh" /> Loading...</Button>}
          </div>
        </div>
      </div>
    );
  }
}

GiftDetailsStep.propTypes = {
  isLoadingStripeKey: PropTypes.bool.isRequired,
  giftDetails: PropTypes.object.isRequired,
  submitGiftDetails: PropTypes.func,
};

export default GiftDetailsStep;
