import React, { useState } from 'react';
import _ from 'lodash';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import GoogleMapReact from 'google-map-react';

import mapStyle from './mapStyle';
import MapMarker from '../Components/MapMarker';
import CountryInnModal from './CountryInnModal';

const Accommodations = () => {
  const [isShowingCountryInnModal, setIsShowingCountryInnModal] = useState(false);

  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row>
                <Col md={12}>
                  <p>For your convenience we have reserved a block at the Country Inn & Suites. There will be a shuttle going from the venue to the hotel throughout the night. Use the booking buttons below to reserve your room for the night of Saturday, May 22nd.</p>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md={12} className="text-center mb-3 mb-md-0">
                  <h4>
                    <a href="https://goo.gl/maps/vwGT7VZC1mQbXC1x5" target="_blank" rel="noreferrer noopener">
                      Country Inn & Suites
                    </a>
                  </h4>

                  <address className="mb-1">
                    <div>
                      1680 Madison Ave
                    </div>
                    <div>
                      Fort Atkinson, WI 53538
                    </div>
                  </address>

                  <a href="tel:(920) 542-1600">(920) 542-1600</a>

                  <div className="mt-2">
                    <Button size="sm" color="primary" onClick={() => setIsShowingCountryInnModal(true)}>Book Country Inn</Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{ height: 500, width: '100%' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: 'AIzaSyBsvaQWGmkypDp5KX0GKzaJI371ilip5Qg' }}
                      defaultCenter={{ lat: 42.943820, lng: -88.863152 }}
                      defaultZoom={14}
                      distanceToMouse={_.noop}
                      options={{ styles: mapStyle }}
                    >
                      <MapMarker lat={42.943603} lng={-88.862626} />
                    </GoogleMapReact>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <CountryInnModal show={isShowingCountryInnModal} hide={() => setIsShowingCountryInnModal(false)} />
    </>
  );
};

Accommodations.propTypes = {
};

export default Accommodations;
