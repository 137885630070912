import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

function CountryInnModal (props) {
  const { show } = props;

  return (
    <Modal isOpen={show} toggle={props.hide}>
      <ModalHeader>Book Country Inn & Suites</ModalHeader>
      <ModalBody>
        <p>
          To book a room at the Country Inn & Suites give them a call at <a href="tel:(920) 542-1600">(920) 542-1600</a> and mention the Rupprecht/Saldivar wedding to get your room in our block for the night of Saturday, May 22nd. The block will close on August 1st so be sure to book before then!
        </p>
      </ModalBody>
    </Modal>
  );
}

CountryInnModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

export default CountryInnModal;
