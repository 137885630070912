const localConfig = {
  publishableApiKey: 'pk_test_51GradzHsyKqoCZfVupl1uzCWcjpO1LdaByms6k5H7Uw8ihxl8YTHcFYfdNqaEonxCqWZvH6wgg0IGkKmoYBxLtOz00cT8blOe2',
  baseUrl: 'https://localhost:44352',
};

const productionConfig = {
  publishableApiKey: 'pk_live_ByKnqtesSpci04BTPbx7FuQ800nqUqAH4O',
  baseUrl: 'https://scottandmicaela.azurewebsites.net/',
};

export const storeKey = 'covid-v2';
export default process.env.NODE_ENV === 'development'
  ? localConfig : productionConfig;
