import React from 'react';
import PropTypes from 'prop-types';
import store from 'store';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { storeKey } from '../appConfig';

function CovidModal (props) {
  const { show } = props;

  function onClosed () {
    store.set(storeKey, true);
  }

  return (
    <Modal isOpen={show} toggle={props.hide} onClosed={onClosed}>
      <ModalHeader>COVID-19</ModalHeader>
      <ModalBody>
        <p>
          We have decided to POSTPONE the wedding reception until May 22nd, 2021. The reception will still be held at Cremery 201 in Fort Atkinson, WI. On September 5th, 2020 we will still be getting married in a smaller ceremony which will be live streamed, more details to follow as we figure things out.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={props.hide}>Right On</Button>
      </ModalFooter>
    </Modal>
  );
}

CovidModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

export default CovidModal;
