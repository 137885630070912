import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

const Divider = ({ id, children }) => (
  <Container id={id} className="mt-5 mb-4 bg-primary text-pastel py-3">
    <h1 className="text-center">{children}</h1>
  </Container>
);

Divider.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
};

export default Divider;
