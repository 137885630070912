import React from 'react';

const K_WIDTH = 40;
const K_HEIGHT = 40;

const markerStyle = {
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  top: -K_HEIGHT,
};

const MapMarker = () => (
  <div className="d-flex align-items-end justify-content-center text-primary">
    <i className="mdi mdi-48px mdi-map-marker" style={markerStyle} />
  </div>
);

export default MapMarker;
