import '../assets/scss/styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import ViewportOnScrollChecker from '../Components/OnScrollChecker';
import Navbar from './Navbar';

const NAVBAR_TRANSPARENT_FUDGE_PERCENT = 0.6;

class Layout extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      isNavbarTransparent: false,
    };
  }

  onScrollPointPassed = isPastScrollPoint => {
    this.setState({ isNavbarTransparent: isPastScrollPoint });
  };

  render () {
    const { isNavbarTransparent } = this.state;
    const { transparentEnabled } = this.props;

    return (
      <>
        <Navbar
          transparentEnabled={transparentEnabled}
          navbarTransparent={isNavbarTransparent}
          openCovidModal={this.props.openCovidModal}
          openLiveStreamModal={this.props.openLiveStreamModal}
        />

        <div className="mb-5">
          {this.props.children}
        </div>

        <ViewportOnScrollChecker
          fudgePercent={NAVBAR_TRANSPARENT_FUDGE_PERCENT}
          onScrollChanged={this.onScrollPointPassed}
        />
      </>
    );
  }
}

Layout.propTypes = {
  transparentEnabled: PropTypes.bool,
  children: PropTypes.any,
  openCovidModal: PropTypes.func.isRequired,
  openLiveStreamModal: PropTypes.func.isRequired,
};

export default Layout;
