import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import Header from '../App/Header';
import { Container } from 'reactstrap';
import AboutUs from '../App/AboutUs';
import Divider from '../App/Divider';
import DayOfDetails from '../App/DayOfDetails';
import Layout from '../App/Layout';
import WeddingParty from '../App/WeddingParty';
import Accommodations from '../App/Accommodations';
import Gifts from '../App/Gifts';
import CovidModal from '../App/CovidModal';
import LiveStreamModal from '../App/LiveStreamModal';
import withLocation from '../App/withLocation';

toastr.options.closeButton = true;
toastr.options.timeOut = 30 * 1000;

class IndexPage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      covidModalIsShowing: false,
      liveStreamModalIsShowing: false,
    };
  }

  componentDidMount () {
    const { showStream } = queryString.parse(this.props.search) || {};

    this.setState({ liveStreamModalIsShowing: _.toLower(showStream) === 'true' });
  }

  showCovidModal = () => {
    this.setState({ covidModalIsShowing: true });
  }

  hideCovidModal = () => {
    this.setState({ covidModalIsShowing: false });
  }

  showLiveStreamModal = () => {
    this.setState({ liveStreamModalIsShowing: true });
  }

  hideLiveStreamModal = () => {
    this.setState({ liveStreamModalIsShowing: false });
  }

  render () {
    const { covidModalIsShowing, liveStreamModalIsShowing } = this.state;

    return (
      <Layout transparentEnabled openCovidModal={this.showCovidModal} openLiveStreamModal={this.showLiveStreamModal}>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Halant:wght@700&family=Sura:wght@700&display=swap"
            rel="stylesheet"
          />
          <title>Scott & Micaela</title>
        </Helmet>
        <Header />

        <CovidModal
          show={covidModalIsShowing}
          hide={this.hideCovidModal}
        />

        <LiveStreamModal
          show={liveStreamModalIsShowing}
          hide={this.hideLiveStreamModal}
        />

        <Container className="mt-3 mb-5">
          <AboutUs />
        </Container>

        <Divider id="day-of">The Day Of</Divider>

        <Container>
          <DayOfDetails />
        </Container>

        <Divider id="wedding-party">Wedding Party</Divider>

        <Container>
          <WeddingParty />
        </Container>

        <Divider id="accommodations">Accommodations</Divider>

        <Container>
          <Accommodations />
        </Container>

        <Divider id="gifts">Gifts</Divider>
        <Container>
          <Gifts />
        </Container>
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  search: PropTypes.any,
};

export default withLocation(IndexPage);
