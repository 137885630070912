import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody } from 'reactstrap';

const GiftType = ({ giftType, setGiftType, Header, children }) => {
  return (
    <Card>
      <CardBody className="d-flex flex-column">
        <h5 className="text-center">{Header}</h5>
        <div>
          {children}
        </div>
        <div className="text-center">
          <Button color="secondary" onClick={() => setGiftType(giftType)}>Contribute</Button>
        </div>
      </CardBody>
    </Card>
  );
};

GiftType.propTypes = {
  giftType: PropTypes.string.isRequired,
  Header: PropTypes.node.isRequired,
  setGiftType: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default GiftType;
